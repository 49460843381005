import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { ParseUrl } from 'shared-ui/lib/services/parse-url';
import { routesConfig } from '../../config/routes.config';
import { useTransferVehicleMutation, useValidateTransferTokenQuery } from '../../generated/graphql';

export const VehicleTransferScene = () => {
  const { t } = useTranslation('VehicleTransferScene');
  const location = useLocation();
  const navigate = useNavigate();

  const token = useMemo(() => ParseUrl.parseLocation(location, 'token'), [location]);

  const { data, loading: queryLoading } = useValidateTransferTokenQuery({
    variables: { token },
  });

  const [transferVehicle, { loading }] = useTransferVehicleMutation({
    variables: { token },
    onCompleted: (d) => {
      if (d.transferVehicle) {
        navigate(routesConfig.VEHICLES.SHOW.replace(':id', d.transferVehicle.vehicleId));
      }
    },
  });

  if (queryLoading) return <AuthLoadingView />;

  let Content = (
    <div className="pb-5">
      <p className="text-lg leading-6 text-center font-bold text-primary my-4">{t('title')}</p>
      <div className="text-center">
        <Button type="button" onClick={transferVehicle} loading={loading}>
          {t('transfer')}
        </Button>
      </div>
    </div>
  );

  if (data?.validateTransferToken.isVehicleOwner) {
    Content = (
      <div className="pb-5">
        <p className="text-lg leading-6 text-center font-bold text-primary mt-4 mb-6">
          {t('youAreOwner')}
        </p>
        <div className="text-center">
          <Link
            className="text-primary underline hover:text-secondary font-bold text-sm"
            to={routesConfig.DASHBOARD}
          >
            {t('backToDashboard')}
          </Link>
        </div>
      </div>
    );
  }

  if (!queryLoading && data === null) {
    Content = (
      <div className="pb-5">
        <p className="text-lg leading-6 text-center font-bold text-primary mt-4 mb-6">
          {t('tokenInvalid')}
        </p>
        <div className="text-center">
          <Link
            className="text-primary underline hover:text-secondary font-bold text-sm"
            to={routesConfig.DASHBOARD}
          >
            {t('backToDashboard')}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>{Content}</Card>
    </div>
  );
};
