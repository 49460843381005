import { Link } from 'react-router-dom';
import { Card } from 'shared-ui/lib/components/Card';
import { Image } from 'shared-ui/lib/components/Image';
import { routesConfig } from '../../../config/routes.config';
import { HomeSceneQueryResult } from '../../../generated/graphql';
import { HomeCardVehicleName } from './HomeCardVehicleName';

type Props = {
  data: HomeSceneQueryResult['data'];
};

export const HomeCardVehicle = ({ data }: Props) => {
  return (
    <>
      {data?.vehicles.list.map((vehicle) => (
        <Link to={routesConfig.VEHICLES.SHOW.replace(':id', vehicle.id)} key={vehicle.id}>
          <Card>
            <HomeCardVehicleName vehicle={vehicle} />
            <div className="text-center mt-1 items-center">
              <Image src={vehicle.photo} h={286} w={500} className="mx-auto rounded-md" />
            </div>
          </Card>
        </Link>
      ))}
    </>
  );
};
