import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [userInputs.email, userInputs.name];

export const userForm = createForm(form);

export type UserFormBody = {
  email: string;
  name: string;
};
