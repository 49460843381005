import { useTranslation } from 'react-i18next';
import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { serviceEventInputs } from '../../inputs/service-event.inputs';

const form: FormInput[] = [
  serviceEventInputs.mileage,
  serviceEventInputs.runningHours,
  {
    type: 'element',
    props: {
      type: 'element',
      name: 'header-1',
      Element: () => {
        const { t } = useTranslation('ServiceEventCompleteReminderForm');
        return <h1>{t('serviceEvents')}</h1>;
      },
    },
  },
  serviceEventInputs.serviceEventArray,
];

export const serviceEventCompleteReminderForm = createForm(form);

export type ServiceEventCompleteReminderFormBody = {
  mileage: number;
  runningHours: number;
  serviceEvents: {
    serviceDate: string;
    description: string;
    photo: string | null | File;
    name: string;
    type: string;
  }[];
};
