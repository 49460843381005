import { useTranslation } from 'react-i18next';

type Props = {
  licenseStatus: string;
};

const classNames = (...classes: string[]) => {
  return classes.filter((x) => x).join(' ');
};

export const LicenseBadge = ({ licenseStatus }: Props) => {
  const { t } = useTranslation('LicenseStatus');
  const classes = 'float-right text-sm';

  if (licenseStatus === 'EXPIRE_SOON') {
    return (
      <span className={classNames(classes, 'bg-yellow-600 text-white py-1 px-1 rounded-lg')}>
        {t('EXPIRE_SOON')}
      </span>
    );
  }

  if (licenseStatus === 'PAID') {
    return <span className={classNames(classes, 'hidden')}>{t('PAID')}</span>;
  }

  if (licenseStatus === 'EXPIRED') {
    return (
      <span className={classNames(classes, 'bg-red-600 text-white py-1 px-1 rounded-lg')}>
        {t('EXPIRED')}
      </span>
    );
  }

  return (
    <span className={classNames(classes, 'bg-yellow-600 text-white py-1 px-1 rounded-lg')}>
      {t('PENDING')}
    </span>
  );
};
