import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Image } from 'shared-ui/lib/components/Image';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { useServiceEventQuery } from '../../generated/graphql';

export const ServiceEventShowScene = () => {
  const { t } = useTranslation('ServiceEventShowScene');
  const { vehicleId, id } = useParams<{ vehicleId: string; id: string }>();
  const { data, loading } = useServiceEventQuery(setupQueryConfig({ id }));

  if (loading || !data) return <AuthLoadingView />;

  const { serviceEvent } = data;

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <div className="bg-white py-8 px-6 sm:px-10 shadow rounded-lg">
        <h2 className="text-lg leading-6 font-bold text-gray-900">{serviceEvent.name}</h2>
        <div className="mt-6">
          <div className="max-w-screen-lg mt-6 mx-auto mb-4">
            <Image src={serviceEvent.photo} className="mx-auto rounded-md" w={672} />
          </div>
          {serviceEvent.partner?.photo ? (
            <div className="grid grid-cols-2 pb-3 space-x-2">
              <Image src={serviceEvent.partner.photo} w={100} />
            </div>
          ) : null}
          {serviceEvent.partner?.name ? (
            <div className="grid grid-cols-2 pb-3 space-x-2">
              <p className="text-lg text-gray-900">{t('partner')}</p>
              <p className="text-lg text-gray-color">{serviceEvent.partner.name}</p>
            </div>
          ) : null}
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('serviceEventType')}</p>
            <p className="text-lg text-gray-color">{t(`ServiceEventType:${serviceEvent.type}`)}</p>
          </div>
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('serviceEventServiceDate')}</p>
            <p className="text-lg text-gray-color">{formatDate(serviceEvent.serviceDate)}</p>
          </div>
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('serviceEventMileage')}</p>
            <p className="text-lg text-gray-color">{serviceEvent.mileage}</p>
          </div>
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('serviceEventRunningHours')}</p>
            <p className="text-lg text-gray-color">{serviceEvent.runningHours}</p>
          </div>
          <div className="grid grid-cols-1 pb-3">
            <p className="text-lg text-gray-900">{t('serviceEventDescription')}</p>
            <p className="text-md mt-4 text-gray-color">{serviceEvent.description}</p>
          </div>
        </div>
        <Link
          className="inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          to={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
        >
          {t('backLink')}
        </Link>
      </div>
    </div>
  );
};
