import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import {
  vehicleCreateForm,
  VehicleCreateFormBody,
} from '../../form/vehicle-create/vehicleCreate.form';
import {
  useDeleteVehicleMutation,
  useUpdateVehicleMutation,
  useVehicleQuery,
} from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const VehicleEditScene = (): JSX.Element => {
  const { t } = useTranslation('VehicleEditScene');
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [updateVehicle, { loading: isMutationLoading }] = useUpdateVehicleMutation();
  const { uploadFile, isImageLoading } = useUploadFile({ type: 'vehicle-image' });

  const [deleteVehicle, { loading: isDeleteLoading }] = useDeleteVehicleMutation({
    variables: { id },
    onCompleted: (data) => {
      if (data) navigate(routesConfig.DASHBOARD);
    },
  });

  const query = useVehicleQuery(setupQueryConfig({ id }));

  const handleSubmit = async ({ photo, ...body }: VehicleCreateFormBody) => {
    try {
      const { data, errors } = await updateVehicle({ variables: { id, body } });
      if (!data) throw new Error(parseGraphqlErrors(errors));
      await uploadFile(photo, id);
      navigate(routesConfig.VEHICLES.SHOW.replace(':id', data.updateVehicle.id));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleDelete = () => {
    if (confirm(t('confirmDeleteVehicle'))) deleteVehicle();
  };

  return (
    <GenericFormScene
      type="edit"
      query={query}
      queryDataPath="vehicle"
      title={t('editVehicle')}
      onSubmit={handleSubmit}
      form={vehicleCreateForm}
      backLink={routesConfig.VEHICLES.SHOW.replace(':id', id)}
      onDelete={handleDelete}
      isSubmitLoading={isMutationLoading || isDeleteLoading || isImageLoading}
    />
  );
};
