import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { routesConfig } from '../../config/routes.config';
import { FaultFormBody, useFaultForm } from '../../forms/fault.form';
import { useCreateFaultMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const FaultCreateScene = (): JSX.Element => {
  const [key, setKey] = useState(1);
  const { t } = useTranslation('FaultCreateScene');
  const form = useFaultForm();
  const navigate = useNavigate();

  const [createFault, { loading }] = useCreateFaultMutation();
  const { uploadFile, isImageLoading } = useUploadFile({
    type: 'fault-image',
  });

  const handleSubmit = async ({ photo, ...body }: FaultFormBody) => {
    const { data, errors } = await createFault({ variables: { body } });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    await uploadFile(photo, data.createFault.id);
    navigate(routesConfig.FAULT.INDEX);
    setKey(key + 1);
  };

  if (!form) return <span />;

  return (
    <div key={key}>
      <GenericFormScene
        type="create"
        title={t('title')}
        onSubmit={handleSubmit}
        form={form}
        isSubmitLoading={loading || isImageLoading}
        backLink={routesConfig.FAULT.INDEX}
      />
    </div>
  );
};
