import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'shared-ui/lib/components/Button';
import { routesConfig } from '../config/routes.config';
import { BusinessRegisterFormBody } from '../form/register/business-register.form';

type Props = {
  loading: boolean;
};

export const RegisterFooter = ({ loading }: Props) => {
  const { t } = useTranslation('RegisterBusinessScene');
  const form = useFormikContext<BusinessRegisterFormBody>();
  return (
    <>
      <Button loading={loading} type="submit" disabled={!form.values.isAccepted} className="w-full">
        {t('register')}
      </Button>
      <div className="text-sm">
        <p className="mt-6 text-gray-color text-center">
          {t('RegisterCommon:iHaveAccount')}{' '}
          <Link
            to={routesConfig.AUTH.LOGIN}
            className="font-medium text-primary hover:text-secondary"
          >
            {t('RegisterCommon:login')}
          </Link>
        </p>
      </div>
    </>
  );
};
