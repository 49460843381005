import {
  ArrayAddButton,
  ArrayInputLayout,
  ArrayRemoveButton,
} from 'shared-ui/lib/components/ArrayInputLayout';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { YupNullableNumber } from 'shared-ui/lib/utils/yup-nullable-number';
import * as Yup from 'yup';
import { getServiceEventTypeOptions } from '../data/get-service-event-type-options';

const mileage: FormInput = {
  type: 'text',
  initValue: '',
  validation: YupNullableNumber,
  props: {
    name: 'mileage',
    type: 'number',
    label: 'ServiceEvent:mileage',
  },
};

const runningHours: FormInput = {
  type: 'text',
  initValue: '',
  validation: YupNullableNumber,
  props: {
    name: 'runningHours',
    type: 'number',
    label: 'ServiceEvent:runningHours',
  },
};

const type: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    placeholder: 'ServiceEvent:chooseType',
    name: 'type',
    label: 'ServiceEvent:type',
    options: getServiceEventTypeOptions(),
  },
};

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    type: 'text',
    label: 'ServiceEvent:name',
  },
};

const description: FormInput = {
  type: 'textarea',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'description',
    label: 'ServiceEvent:description',
  },
};

const serviceDate: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'serviceDate',
    type: 'date',
    label: 'ServiceEvent:date',
  },
};

const photo: FormInput = {
  type: 'file',
  initValue: null,
  props: {
    name: 'photo',
    label: 'ServiceEvent:picture',
  },
};

const serviceEventArray: FormInput = {
  type: 'array',
  props: {
    name: 'serviceEvents',
    label: 'ServiceEvent:maintenance',
    initialValues: {
      type: '',
      name: '',
      serviceDate: '',
      description: '',
    },
    ArrayElement: ArrayInputLayout,
    AddElement: ArrayAddButton,
    RemoveElement: ArrayRemoveButton,
    elements: [type, name, serviceDate, photo, description],
  },
};

export const serviceEventInputs = {
  type,
  name,
  description,
  serviceDate,
  photo,
  mileage,
  runningHours,
  serviceEventArray,
};
