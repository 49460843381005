import { GeneratableSelectInputOptions } from 'shared-ui/lib/components/releox/form/SelectInput';

const vehicleTypes = [
  'MOTOR_VEHICLE',
  'MOTOR_CARAVAN',
  'MOTORCYCLE',
  'BICYCLE',
  'LIGHT_ELECTRIC_VEHICLE',
  'VEHICLE_OF_HISTORIC_INTEREST',
  'TRACTOR',
  'PUBLIC_WORKS_VEHICLE',
  'OFF_ROAD_VEHICLE',
  'WORK_MACHINE_MOUNTED_ON_A_VEHICLE_CHASSIS',
  'TRAILER',
  'ACCESSIBLE_VEHICLE',
  'MOPED',
  'OTHER',
];

export const getVehicleTypeOptions = (): GeneratableSelectInputOptions['props']['options'] => {
  const options = vehicleTypes.map((value) => ({
    value: value,
    label: `VehicleType:${value}`,
  }));

  return options;
};
