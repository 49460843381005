import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import {
  ServiceEventCompleteReminderFormBody,
  serviceEventCompleteReminderForm,
} from '../../form/service-event/service-event-complete-reminder.form';
import { useCompleteReminderMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const ServiceEventCompleteReminderScene = (): JSX.Element => {
  const { t } = useTranslation('ServiceEventCompleteReminderScene');
  const navigate = useNavigate();
  const { vehicleId, id } = useParams<{ vehicleId: string; id: string }>();

  const { isImageLoading, uploadFile } = useUploadFile({ type: 'service-event-image' });
  const [completeReminder, { loading }] = useCompleteReminderMutation();

  const handleSubmit = async (body: ServiceEventCompleteReminderFormBody) => {
    // Confirm
    if (confirm(t('confirmSave')) === false) return;

    const { data, errors } = await completeReminder({
      variables: {
        body: {
          ...body,
          serviceEvents: body.serviceEvents.map((se) => _.omit(se, 'photo')),
          reminderId: id,
          vehicleId,
        },
      },
    });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    const requests = data.completeReminder.ids.map((reminderId, i) => {
      if (body.serviceEvents[i].photo instanceof File) {
        return uploadFile(body.serviceEvents[i].photo, reminderId);
      }
    });
    await Promise.all(requests);
    navigate(routesConfig.VEHICLES.SHOW.replace(':id', vehicleId));
  };

  return (
    <>
      <GenericFormScene
        blocker={(event) => {
          if (confirm(t('confirmLeave')) === false) {
            event.preventDefault();
          }
        }}
        type="create"
        title={t('addServiceEvent')}
        onSubmit={handleSubmit}
        form={serviceEventCompleteReminderForm}
        isSubmitLoading={loading || isImageLoading}
        backLink={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
      />
    </>
  );
};
