import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'shared-ui/lib/components/Button';
import { Image } from 'shared-ui/lib/components/Image';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import {
  useFaultShowSceneQuery,
  useMarkAsReadMutation,
  useUpdateFaultAsDoneMutation,
} from '../../generated/graphql';

export const FaultShowScene = () => {
  const { t } = useTranslation('FaultShowScene');
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useFaultShowSceneQuery(setupQueryConfig({ id }));
  const [update, { loading: mutationLoading }] = useUpdateFaultAsDoneMutation();
  const [markAsRead, { loading: markAsReadLoading }] = useMarkAsReadMutation({
    variables: { id },
    onCompleted: (_data) => {
      if (_data.markAsRead) {
        refetch();
      }
    },
  });

  const handleUpdate = useCallback(async () => {
    await update({ variables: { id } });
    navigate(routesConfig.FAULT.INDEX);
  }, [id, update, navigate]);

  if (loading || !data) return <AuthLoadingView />;

  const { fault } = data;

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <div className="bg-white py-8 px-6 sm:px-10 shadow rounded-lg">
        <div className="mt-6">
          <div className="max-w-screen-lg mt-6 mx-auto mb-4">
            <Image src={fault.photo} className="mx-auto rounded-md" w={672} />
          </div>
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('userName')}</p>
            <p className="text-lg text-gray-color">{fault.user.name}</p>
          </div>
          <div className="grid grid-cols-2 pb-3 space-x-2">
            <p className="text-lg text-gray-900">{t('createdAt')}</p>
            <p className="text-lg text-gray-color">{formatDate(fault.createdAt)}</p>
          </div>
          <div className="grid grid-cols-1 pb-3">
            <p className="text-lg text-gray-900">{t('description')}</p>
            <p className="text-md mt-4 text-gray-color">{fault.description}</p>
          </div>
        </div>

        <div className="space-y-2 md:space-y-0">
          <Link
            className="block md:inline-block md:float-right text-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
            to={routesConfig.FAULT.CREATE_REMINDER.replace(':vehicleId', fault.vehicle.id).replace(
              ':faultId',
              fault.id
            )}
          >
            {t('createReminder')}
          </Link>
          {data.fault.isRead === false && (
            <Button
              type="button"
              loading={markAsReadLoading}
              onClick={markAsRead}
              className="w-full md:w-auto md:float-right md:mr-2 py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
            >
              {t('markAsRead')}
            </Button>
          )}
          <Button
            type="button"
            loading={mutationLoading}
            onClick={handleUpdate}
            className="w-full md:w-auto md:float-right md:mr-2 py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          >
            {t('delete')}
          </Button>
          <Link
            className="block md:inline-block text-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
            to={routesConfig.FAULT.INDEX}
          >
            {t('backLink')}
          </Link>
        </div>
      </div>
    </div>
  );
};
