import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { SidebarCompany } from 'shared-ui/lib/components/SidebarCompany';
import Logo from '../images/AJONEUVONI_TUNNUS_RGB_Small.png';

export const classNames = (...classes: string[]) => {
  return classes.filter((x) => x).join(' ');
};

export type DashbaordNavItem = {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

type Props = {
  children: JSX.Element | JSX.Element[];
  homeUrl: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  nav: DashbaordNavItem[];
};

type SidebarLinkProps = {
  to: string;
  children: JSX.Element | string | (JSX.Element | string)[];
  baseClass: string;
  exact?: boolean;
  onClick(): void;
};

const SidebarLink = (props: SidebarLinkProps) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: props.exact || false });

  const notActiveClass = 'text-primary hover:bg-primary hover:text-white';
  const activeClass = 'bg-primary text-secondary font-bold';

  let dynamicClass = notActiveClass;

  if (match) dynamicClass = activeClass;

  return (
    <Link
      onClick={props.onClick}
      className={[dynamicClass, props.baseClass].join(' ')}
      to={props.to}
    >
      {props.children}
    </Link>
  );
};

export const DashboardLayout = (props: Props) => {
  const { user, homeUrl, children, nav } = props;
  const { t } = useTranslation('SideBar');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-primary" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-12 w-auto" src={Logo} alt="Ajoneuvoni logo" />
                    <p className="text-primary font-bold">ajoneuvoni.fi</p>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {nav.map((item) => (
                      <SidebarLink
                        exact={item.exact}
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={item.href}
                        baseClass="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-secondary group-hover:text-white'
                              : 'text-secondary group-hover:text-white',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {t(item.name)}
                      </SidebarLink>
                    ))}
                  </nav>
                </div>
                {user.partner ? (
                  <SidebarCompany
                    userName={user.name}
                    companyName={user.partner.name}
                    photo={user.partner.photo}
                  />
                ) : undefined}
                {user.business ? (
                  <SidebarCompany
                    userName={user.name}
                    companyName={user.business.name}
                    photo={user.business.photo}
                  />
                ) : undefined}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img className="h-12 w-auto" src={Logo} alt="Ajoneuvoni logo" />
                <p className="text-primary font-bold">ajoneuvoni.fi</p>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {nav.map((item) => (
                  <SidebarLink
                    exact={item.exact}
                    onClick={() => setSidebarOpen(false)}
                    baseClass="group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    key={item.name}
                    to={item.href}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-secondary group-hover:text-white'
                          : 'text-secondary group-hover:text-white',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {t(item.name)}
                  </SidebarLink>
                ))}
              </nav>
            </div>

            {user.partner ? (
              <SidebarCompany
                userName={user.name}
                companyName={user.partner.name}
                photo={user.partner.photo}
              />
            ) : undefined}
            {user.business ? (
              <SidebarCompany
                userName={user.name}
                companyName={user.business.name}
                photo={user.business.photo}
              />
            ) : undefined}
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow md:hidden">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <Link to={homeUrl} className="font-bold uppercase mt-5 text-lg text-primary">
                  Ajoneuvoni.fi
                </Link>
              </div>
              {/* <div className="mt-5">
                <Link to={homeUrl} className="text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </Link>
              </div>
              <div className="mt-5 ml-5">
                <Link to={homeUrl} className="text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  </svg>
                </Link>
              </div> */}
            </div>
          </div>
          <main className="flex-1">
            <div className="p-2 sm:p-3">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};
