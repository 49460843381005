import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { CardTitle } from 'shared-ui/lib/components/CardTitle';
import { routesConfig } from '../config/routes.config';
import ReFetchContext from '../contexes/ReFetchContext';
import { useResendConfirmMutation } from '../generated/graphql';

export const ActivateYourAccount: FC = () => {
  const navigate = useNavigate();
  const refetch = useContext(ReFetchContext);
  const [disabled, setDisable] = useState(false);
  const [resendConfirmMutation] = useResendConfirmMutation({
    onCompleted: () => {
      // wait 1 min
      setTimeout(() => {
        setDisable(false);
      }, 60000);
    },
  });

  const onClick = () => {
    navigate(routesConfig.AUTH.LOGOUT);
  };

  const onResend = async () => {
    setDisable(true);
    resendConfirmMutation();
  };

  // Run refetch once per 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <div className="max-w-md m-auto mt-32">
      <Card>
        <CardTitle>Vahvista tilisi</CardTitle>
        <p>
          Kiitos rekisteröitymisestäsi! Saadaksesi täyden pääsyn palveluun, sinun tulee vahvistaa
          sähköpostiosoitteesi. Tarkista sähköpostisi ja klikkaa siellä olevaa linkkiä.
        </p>
        <p className="mt-2">
          Jos et ole saanut sähköpostia, tarkista roskapostikansio. Uudelleen lähetyksen jälkeen
          odota 60 sekunttia
        </p>
        <div className="text-center pt-4">
          <Button type="button" onClick={onClick}>
            Kirjaudu ulos
          </Button>
          <Button type="button" className="ml-2" onClick={onResend} disabled={disabled}>
            Lähetä uudelleen
          </Button>
        </div>
      </Card>
    </div>
  );
};
