import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { serviceEventForm, ServiceEventFormBody } from '../../form/service-event/service-event';
import { useEditServiceEventQuery, useUpdateServiceEventMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const ServiceEventEditScene = (): JSX.Element => {
  const { t } = useTranslation('ServiceEventEditScene');
  const navigate = useNavigate();
  const { vehicleId, id } = useParams<{ vehicleId: string; id: string }>();
  const { uploadFile, isImageLoading } = useUploadFile({ type: 'service-event-image' });

  const [updateServiceEvent, { loading }] = useUpdateServiceEventMutation();

  const query = useEditServiceEventQuery(setupQueryConfig({ id }));

  const handleSubmit = async ({ photo, ...body }: ServiceEventFormBody) => {
    const { data, errors } = await updateServiceEvent({ variables: { id, body: body } });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    await uploadFile(photo, data.updateServiceEvent.id);
    navigate(routesConfig.SERVICE_EVENTS.SHOW.replace(':id', id).replace(':vehicleId', vehicleId));
  };

  return (
    <GenericFormScene
      type="edit"
      title={t('editServiceEvent')}
      query={query}
      queryDataPath="serviceEvent"
      onSubmit={handleSubmit}
      form={serviceEventForm}
      isSubmitLoading={loading || isImageLoading}
      backLink={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
    />
  );
};
