import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { serviceEventForm, ServiceEventFormBody } from '../../form/service-event/service-event';
import { useCreateServiceEventMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const ServiceEventCreateScene = (): JSX.Element => {
  const { t } = useTranslation('ServiceEventCreateScene');
  const navigate = useNavigate();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { uploadFile, isImageLoading } = useUploadFile({ type: 'service-event-image' });

  const [createServiceEvent, { loading }] = useCreateServiceEventMutation();

  const handleSubmit = async ({ photo, ...body }: ServiceEventFormBody) => {
    const { data, errors } = await createServiceEvent({
      variables: { body: { ...body, vehicleId } },
    });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    await uploadFile(photo, data.createServiceEvent.id);
    navigate(
      routesConfig.SERVICE_EVENTS.SHOW.replace(':vehicleId', vehicleId).replace(
        ':id',
        data.createServiceEvent.id
      )
    );
  };

  return (
    <GenericFormScene
      blocker={(event) => {
        if (confirm(t('confirmLeave')) === false) {
          event.preventDefault();
        }
      }}
      type="create"
      title={t('addServiceEvent')}
      onSubmit={handleSubmit}
      form={serviceEventForm}
      isSubmitLoading={loading || isImageLoading}
      backLink={routesConfig.VEHICLES.SHOW.replace(':id', vehicleId)}
    />
  );
};
