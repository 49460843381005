export default {
  fi: {
    VehicleTransferScene: {
      title: 'Alla olevaa painiketta painamalla saat siirrettyä ajoneuvon omistajuuden itsellesi',
      transfer: 'Siirrä omistajuus',
      youAreOwner: 'Olet jo ajoneuvon omistaja',
      backToDashboard: 'Takaisin etusivulle',
      tokenInvalid: 'Siirtoavain on väärin tai erääntynyt',
    },
  },
};
