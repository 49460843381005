export const StripeSuccessSceneTranslation = {
  fi: {
    StripeSuccessScene: {
      paymentSuccess: 'Ostotapahtuma onnistui!',
      bought: 'Ostettu:',
      price: 'Hinta (sis. alv):',
      poletsCount: 'Sinulla on poletteja yhteensä:',
      toVehicle: 'Siirry ajoneuvon tietoihin',
    },
  },
};
