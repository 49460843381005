import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { routesConfig } from '../../config/routes.config';
import {
  vehicleCreateForm,
  VehicleCreateFormBody,
} from '../../form/vehicle-create/vehicleCreate.form';
import { useCreateVehicleMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const VehicleCreateScene = (): JSX.Element => {
  const { t } = useTranslation('VehicleCreateScene');
  const navigate = useNavigate();
  const [createVehicle, { loading }] = useCreateVehicleMutation();
  const { uploadFile, isImageLoading } = useUploadFile({
    type: 'vehicle-image',
  });

  const handleSubmit = async ({ photo, ...body }: VehicleCreateFormBody) => {
    const { data, errors } = await createVehicle({ variables: { body } });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    await uploadFile(photo, data.createVehicle.id);
    navigate(routesConfig.VEHICLES.CHOOSE_PAYMENT.replace(':id', data.createVehicle.id));
  };

  return (
    <GenericFormScene
      type="create"
      title={t('addVehicle')}
      onSubmit={handleSubmit}
      form={vehicleCreateForm}
      isSubmitLoading={loading || isImageLoading}
      backLink={routesConfig.DASHBOARD}
    />
  );
};
