import { Field, Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import {
  CreateCheckoutSessionQuery,
  useCreateCheckoutSessionLazyQuery,
  useStripeSceneQuery,
} from '../../generated/graphql';

export const StripeScene = (): JSX.Element => {
  const { t } = useTranslation('StripeScene');
  const { id } = useParams<{ id: string }>();
  const onCompleted = useCallback((onCompleteData: CreateCheckoutSessionQuery) => {
    if (onCompleteData) {
      window.location.replace(onCompleteData.createCheckoutSession.url);
    }
  }, []);

  const { loading: isQueryLoading } = useStripeSceneQuery({ variables: { id } });

  const [createCheckoutSession, { loading }] = useCreateCheckoutSessionLazyQuery({
    onCompleted,
    variables: { vehicleId: id },
  });

  const header = (
    <>
      <p className="text-lg leading-6 font-bold text-primary mb-6">{t('title')}</p>
      <p className="text-lg font-bold text-gray-700">{t('subTitle')}</p>

      <div className="border-t border-gray-200 mb-6" />
      <div className="grid grid-cols-2 mb-3">
        <p className="text-sm font-bold text-gray-color">{t('feature1')}</p>
        <p className="text-sm font-bold text-gray-700 text-right">{t('include')}</p>
      </div>
      <div className="grid grid-cols-2 mb-3">
        <p className="text-sm font-bold text-gray-color">{t('feature2')}</p>
        <p className="text-sm font-bold text-gray-700 text-right">{t('include')}</p>
      </div>
      <div className="grid grid-cols-2 mb-6">
        <p className="text-sm font-bold text-gray-color">{t('feature3')}</p>
        <p className="text-sm font-bold text-gray-700 text-right">{t('include')}</p>
      </div>

      <div className="border-t border-gray-200 mb-6" />
      <p
        className="text-sm text-gray-color"
        dangerouslySetInnerHTML={{ __html: t('instructions1') }}
      />
      <p className="text-sm text-gray-color mt-2">{t('instructions2')}</p>
    </>
  );

  const footer = (
    <div>
      <div className="grid grid-cols-2 my-6">
        <p className="text-md font-bold text-gray-700">{t('price')}</p>
        <p className="text-right text-md font-bold text-gray-700">{t('cost')}</p>
      </div>
      <Formik initialValues={{ isAccepted: false }} onSubmit={() => createCheckoutSession()}>
        {({ values }) => (
          <Form>
            <div className="mb-3">
              <Field name="isAccepted" type="checkbox" className="mr-2" />
              {t('acceptTerms')}
            </div>

            <Button
              type="submit"
              loading={loading}
              disabled={!values.isAccepted}
              className="float-right"
            >
              {t('pay')}
            </Button>
            <Link
              to={routesConfig.VEHICLES.CHOOSE_PAYMENT.replace(':id', id)}
              className="float-left py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
            >
              {t('back')}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );

  if (isQueryLoading) return <AuthLoadingView />;

  return (
    <div className="grid grid-co3ls-1 mt-1 sm:mt-0 max-w-screen-sm m-auto">
      <Card>
        {header}
        {footer}
      </Card>
    </div>
  );
};
