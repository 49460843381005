export default {
  fi: {
    UserEditScene: {
      editInformation: 'Muokkaa tietoja',
      deleteUserTitle: 'Pyydä käyttäjätilin poistoa',
      deleteUserText:
        'Jos haluat poistaa käyttäjätilisi, paina alla olevaa painiketta. Tämän jälkeen käyttäjätilisi poistetaan ja kaikki siihen liittyvät tiedot poistetaan pysyvästi.',
      deleteUserButton: 'Poista käyttäjätili',
      deleteUserConfirm: 'Haluatko varmasti poistaa käyttäjätilisi?',
      deleteUserSuccess: 'Käyttäjätilin poistopyyntö lähetetty',
    },
  },
};
