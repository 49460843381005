"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayRemoveButton = exports.ArrayAddButton = exports.ArrayInputLayout = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var Button_1 = require("./Button");
var ArrayInputLayout = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)("div", { className: "space-y-4", children: children }));
};
exports.ArrayInputLayout = ArrayInputLayout;
var ArrayAddButton = function (_a) {
    var onClick = _a.onClick;
    var t = (0, react_i18next_1.useTranslation)('ArrayInputLayoutTranslation').t;
    return ((0, jsx_runtime_1.jsx)("button", { onClick: onClick, className: "w-full py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300", children: t('add') }));
};
exports.ArrayAddButton = ArrayAddButton;
var ArrayRemoveButton = function (_a) {
    var onClick = _a.onClick;
    var t = (0, react_i18next_1.useTranslation)('ArrayInputLayoutTranslation').t;
    return ((0, jsx_runtime_1.jsx)(Button_1.Button, { type: "button", onClick: onClick, className: "bg-red-color", children: t('deleteServiceEvent') }));
};
exports.ArrayRemoveButton = ArrayRemoveButton;
