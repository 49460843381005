import { GeneratableSelectInputOptions } from 'shared-ui/lib/components/releox/form/SelectInput';

const serviceEventTypes = [
  'ALCOLOCK',
  'FRONT_BREAK',
  'AIR_FILTER',
  'TIMING_BELT',
  'GAS_EQUIPMENTS',
  'INSPECTION',
  'SUMMER_TIRE',
  'SPEED_LIMITER',
  'TACHOGRAPH',
  'FUEL_FILTER',
  'EXTINGUISHER',
  'REAR_BREAK',
  'TAXIMETER',
  'WINTER_TIRE',
  'LIGHTS',
  'OIL',

  'OTHER',
];

export const getServiceEventTypeOptions = (): GeneratableSelectInputOptions['props']['options'] => {
  const options = serviceEventTypes.map((value) => ({
    value: value,
    label: `ServiceEventType:${value}`,
  }));

  return options;
};
