export default {
  fi: {
    ServiceEventShowScene: {
      serviceEventType: 'Huoltotyyppi',
      serviceEventServiceDate: 'Huoltopäivä',
      serviceEventMileage: 'Kilometrit',
      serviceEventRunningHours: 'Käyttötunnit',
      serviceEventDescription: 'Kuvaus',
      backLink: 'Takaisin',
      partner: 'Yritys',
    },
  },
};
