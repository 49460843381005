export const StripeUseVoucherSceneTranslation = {
  fi: {
    StripeUseVoucherScene: {
      title: 'Käytä ajoneuvoni kuponki',
      instructions1:
        'Jos olet saanut <span class="text-red-500">aktivointikoodin</span> tai olet laskutusasiakas.',
      instructions2: '- Syötä koodi kohtaan kuponki ja paina "käytä kuponki"',
      instructions3: 'Tämän jälkeen saat käyttöösi:',
      instructions4:'- 12 kk käyttöoikeus sähköiseen huoltokirjaan',
      instructions5:'- 1 ajoneuvo',
      instructions6 :'- Sisältää huoltomerkinnät ja muistutukset',
      buy: 'Käytä kuponki',
      back: 'Takaisin',
    },
  },
};
