export const StripeChoosePaymentSceneTranslation = {
  fi: {
    StripeChoosePaymentScene: {
      title: 'Valitse maksutapa',
      instructions1:
        '- Jos sinulla on <span class="text-red-500">aktivointikoodi</span>, paina <strong>"käytä kuponki"</strong>',
      instructions2:'- Jos ostat vuosilisenssin, paina <strong>"siirry ostamaan"</strong>',
      instructions3:'<span class="text-red-500">Huom!</span> Mahdollisen alennuskoodin annat "siirry ostamaan" jälkeen maksutapahtuman yhteydessä.',
      usePayment: 'Siirry ostamaan',
      useVoucher: 'Käytä kuponki',
    },
  },
};
