import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Image } from 'shared-ui/lib/components/Image';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../../../../config/routes.config';
import { VehicleShowQuery } from '../../../../generated/graphql';
import { MaintenanceListItemBusinessIcon } from './MaintenanceListItemBusinessIcon';
import { MaintenanceListItemIcon } from './MaintenanceListItemIcon';
import { MaintenanceListItemIconEdit } from './MaintenanceListItemIconEdit';
import { MaintenanceListItemIconShow } from './MaintenanceListItemIconShow';

type Props = {
  vehicleId: string;
  serviceEvent: VehicleShowQuery['vehicle']['serviceEvents'][0];
};

export const MaintenanceListItem = ({ serviceEvent, vehicleId }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="mt-6">
      <div className="grid grid-cols-3 items-center">
        <div className="inline-flex col-span-2 items-center">
          {serviceEvent.partner ? (
            serviceEvent.partner?.photo ? (
              <Image
                placeholderType="thumbnail"
                src={serviceEvent.partner?.photo}
                w={40}
                h={40}
                className="h10 w-10 rounded-md"
              />
            ) : (
              <MaintenanceListItemBusinessIcon />
            )
          ) : (
            <MaintenanceListItemIcon />
          )}
          <div className="grid">
            <p className="text-lg text-gray-900 ml-2 truncate">
              {serviceEvent.name} ({t(`ServiceEventType:${serviceEvent.type}`)})
            </p>
            <p className="text-lg text-gray-color ml-2">{formatDate(serviceEvent.serviceDate)}</p>
          </div>
        </div>
        <div className="flex justify-end">
          <MaintenanceListItemIconShow
            to={routesConfig.SERVICE_EVENTS.SHOW.replace(':vehicleId', vehicleId).replace(
              ':id',
              serviceEvent.id
            )}
          />
          {!serviceEvent.isLocked ? (
            <MaintenanceListItemIconEdit
              to={routesConfig.SERVICE_EVENTS.EDIT.replace(':vehicleId', vehicleId).replace(
                ':id',
                serviceEvent.id
              )}
            />
          ) : undefined}
        </div>
      </div>
      <div>
        <p className="text-md text-gray-color ml-2">
          {_.truncate(serviceEvent.description || '-', {
            length: 115,
            separator: ' ',
          })}
        </p>
      </div>
    </div>
  );
};
