import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'shared-ui/lib/components/Card';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';

export const StripeSuccess = () => {
  const { t } = useTranslation('StripeSuccessScene');
  const { id } = useParams<{ id: string }>();
  return (
    <div className="grid grid-co3ls-1 mt-1 sm:mt-0 max-w-screen-sm m-auto">
      <Card>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-24 w-24 m-auto text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="text-lg leading-6 text-center font-bold text-primary my-6">
          {t('paymentSuccess')}
        </p>
        <div className="text-center">
          <Link
            to={routesConfig.VEHICLES.SHOW.replace(':id', id)}
            className="block text-sm font-medium mb-2 text-primary text-center hover:underline"
          >
            {t('toVehicle')}
          </Link>
        </div>
      </Card>
    </div>
  );
};
