import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'shared-ui/lib/components/Card';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';

export const StripeChoosePaymentScene = (): JSX.Element => {
  const { t } = useTranslation('StripeChoosePaymentScene');

  const { id } = useParams<{ id: string }>();

  const header = (
    <>
      <p className="text-lg leading-6 font-bold text-primary mb-6">{t('title')}</p>


      <p className="text-sm text-gray-700" dangerouslySetInnerHTML={{__html:t('instructions1') }}/>
      <p className="text-sm text-gray-700"  dangerouslySetInnerHTML={{__html:t('instructions2') }} />
      <p className="text-sm text-gray-700 mt-5"  dangerouslySetInnerHTML={{__html:t('instructions3') }} />
    </>
  );

  const footer = (
    <>
      <div className="mt-5">
        <Link
          to={routesConfig.VEHICLES.BUY.replace(':id', id)}
          className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
          {t('usePayment')}
        </Link>
        <Link
          to={routesConfig.VEHICLES.VOUCHER.replace(':id', id)}
          className="float-left py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
          {t('useVoucher')}
        </Link>
      </div>
    </>
  );

  return (
    <div className="grid grid-co3ls-1 mt-1 sm:mt-0 max-w-screen-sm m-auto">
      <Card>
        {header}
        {footer}
      </Card>
    </div>
  );
};
