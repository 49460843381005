import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GenericIndexScene } from 'shared-ui/lib/components/table/TableX';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { userColumns } from '../../columns/user.columns';
import { routesConfig } from '../../config/routes.config';
import { useDeleteUserMutation, useTeamIndexSceneLazyQuery } from '../../generated/graphql';

export const TeamIndexScene = (): JSX.Element => {
  const query = useTeamIndexSceneLazyQuery(setupQueryConfig());
  const { t } = useTranslation('TeamIndexScene');
  const [deleteAction] = useDeleteUserMutation({
    onCompleted: (data) => {
      if (data.deleteUser) {
        query[0]();
      }
    },
  });

  return (
    <GenericIndexScene
      tNs="TeamIndexScene"
      dataPath="users.list"
      countPath="users.totalCount"
      columns={[
        userColumns.name,
        userColumns.email,
        userColumns.isEmployee,
        userColumns.deleteAction(deleteAction),
      ]}
      query={query}
      Tool={
        <Link
          to={routesConfig.SETTINGS.TEAM.INVITE}
          className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
          {t('add')}
        </Link>
      }
    />
  );
};
