export default {
  fi: {
    VehicleShowScene: {
      emptyRemainder: 'Sinulla ei ole yhtään muistutusta.',
      emptyRemainderAddNew: 'Lisää uusi muistutus tästä.',
      emptyServiceEvent: 'Sinulla ei ole yhtään huoltoa tehtynä.',
      emptyServiceEventAddNew: 'Lisää uusi huolto tästä.',
      vehicleInformation: 'Ajoneuvon tiedot',
      vehicleName: 'Nimi',
      vehicleRegisterNumber: 'Rekisterinumero',
      vehicleVIN: 'Valmistenumero',
      vehicleMileage: 'Kilometrit',
      vehicleRunningHours: 'Käyttötunnit',
      vehicleBrand: 'Merkki',
      vehicleModel: 'Malli',
      vehicleModelYear: 'Vuosimalli',
      vehicleCommissioning: 'Käyttöönotto',
      vehicleWarranty: 'Takuu',
      vehicleToken: 'Hakutunniste',
      vehicleType: 'Ajoneuvon tyyppi',
      vehicleDescription: 'Kuvaus',
      reminder: 'Muistutus',
      serviceEvent: 'Huoltotapahtumat',
      showDetails: 'Näytä lisää...',
      hideDetails: 'Piilota...',
      transferLinkSent: 'Ajoneuvon omistajuuden vaihto-linkki on lähetetty sähköpostiinne',
      printMaintenanceBoook: 'Tulosta huoltokirja',
      pleaseReactivateLicense: 'Ajoneuvolla ei ole tällä hetkellä aktiivista lisenssiä.',
      vehicleMaintenance: 'Huolto',
      vehicleMaintenanceDay: 'Huoltopäivä',
      vehicleMaintenanceType: 'Huoltotyyppi',
      vehicleMaintenanceRunninghours: 'Käyttötunnit',
      partner: 'Partneri',
    },
  },
};
