export default {
  fi: {
    ServiceEventCompleteReminderScene: {
      addServiceEvent: 'Lisää huoltotapahtuma',
      confirmLeave: 'Haluatko varmasti poistua sivulta? Tallentamattomat tiedot menetetään.',
      confirmSave:
        'Haluatko varmasti tallentaa tiedot? Muistutus poistetaan tallennuksen yhteydessä',
    },
  },
};
