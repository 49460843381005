import { GenericIndexScene } from 'shared-ui/lib/components/table/TableX';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { vehicleColumns } from '../../columns/vehicle.columns';
import { useVehicleIndexSceneLazyQuery } from '../../generated/graphql';

export const VehicleIndexScene = (): JSX.Element => {
  const query = useVehicleIndexSceneLazyQuery(setupQueryConfig());

  return (
    <GenericIndexScene
      tNs="VehicleIndexScene"
      dataPath="vehicles.list"
      countPath="vehicles.totalCount"
      query={query}
      columns={[
        vehicleColumns.name,
        vehicleColumns.registerNumber,
        vehicleColumns.VIN,
        vehicleColumns.license,
        vehicleColumns.model,
        vehicleColumns.brand,
        vehicleColumns.linkToVehicle,
      ]}
    />
  );
};
