import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'shared-ui/lib/components/Card';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';

export const StripeCancel = () => {
  const { t } = useTranslation('StripeCancelScene');
  const { id } = useParams<{ id: string }>();

  return (
    <div className="grid grid-co3ls-1 mt-1 sm:mt-0 max-w-screen-sm m-auto">
      <Card>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-24 w-24 m-auto text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="text-sm font-medium text-gray-color mt-3 mb-2 text-center">
          {t('paymentCancelled')}
        </p>
        <Link
          to={routesConfig.VEHICLES.BUY.replace(':id', id)}
          className="block text-sm font-medium mb-2 text-primary text-center hover:underline"
        >
          {t('backLink')}
        </Link>
      </Card>
    </div>
  );
};
