export default {
  fi: {
    RegisterCommon: {
      iHaveAccount: 'Minulla on jo tunnukset,',
      login: 'Kirjaudu',
    },
    RegisterConsumerScene: {
      register: 'Rekisteröi tunnus',
    },
    RegisterBusinessScene: {
      register: 'Rekisteröidy',
      client: 'Yksityishenkilö',
      businessClient: 'Yritys',
      idType: 'Valitse tunnustyyppi:',
    },
  },
};
