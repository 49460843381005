import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { routesConfig } from '../../config/routes.config';
import { voucherForm, VoucherFormBody } from '../../forms/voucher.form';
import { useUseVoucherMutation } from '../../generated/graphql';

export const Footer = () => {
  const form = useFormikContext<VoucherFormBody>()
  const { t } = useTranslation('StripeUseVoucherScene');
  const { id } = useParams<{ id: string }>();
  return (
    <div className="mt-3">
                
      <Button
        type="submit"
        disabled={!form.values.isAccepted}
        className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
        {t('buy')}
      </Button>
      <Link
        to={routesConfig.VEHICLES.CHOOSE_PAYMENT.replace(':id', id)}
        className="float-left py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
        {t('back')}
      </Link>
    </div>
  )
}


export const StripeUseVoucherScene = (): JSX.Element => {
  const { t } = useTranslation('StripeUseVoucherScene');
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [claimVoucher] = useUseVoucherMutation({
    onCompleted: (data) => {
      if (data.useVoucher) {
        toast.success(t('Common:success'));
        navigate(routesConfig.VEHICLES.SHOW.replace(':id', id));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: VoucherFormBody) => {
      claimVoucher({
        variables: {
          voucher: body.voucher,
          vehicleId: id,
        },
      });
    },
    [claimVoucher, id]
  );

  const header = (
    <>
      <p className="text-lg leading-6 font-bold text-primary mb-6">{t('title')}</p>
      <p className="text-sm text-gray-700" dangerouslySetInnerHTML={{__html:t('instructions1') }}/>
      <p className="text-sm text-gray-700 mt-1">{t('instructions2')}</p>
      <p className="text-sm text-gray-700 mt-4">{t('instructions3')}</p>
      <p className="text-sm text-gray-700 font-bold mt-1">{t('instructions4')}</p>
      <p className="text-sm text-gray-700 font-bold">{t('instructions5')}</p>
      <p className="text-sm text-gray-700 font-bold">{t('instructions6')}</p>

    </>
    
  );

  const footer = (
    <>
      <div className="mt-5">
        <FormGen
          form={voucherForm}
          wrapperClassNames="space-y-4"
          onSubmit={handleSubmit}
          FooterElement={<Footer />}
        />
      </div>
    </>
  );

  return (
    <div className="grid grid-co3ls-1 mt-1 sm:mt-0 max-w-screen-sm m-auto">
      <Card>
        {header} {footer}
      </Card>
    </div>
  );
};
