import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { BackgroundClass } from '../../components/BackgroundClass';
import { useVehicleTextOnlyQuery } from '../../generated/graphql';

export const VehicleTextOnlyScene = (): JSX.Element => {
  const { t } = useTranslation('VehicleShowScene');
  const { id } = useParams<{ id: string }>();
  const { data } = useVehicleTextOnlyQuery({ variables: { id } });

  if (!data) return <span />;

  const { vehicle } = data;

  return (
    <div className="max-w-5xl mx-auto">
      <BackgroundClass className="white-bg" />
      <div className="mt-5">
        <div>
          <h2 className="text-2xl leading-6 font-bold">{t('vehicleInformation')}</h2>
          <div className="flex mt-5">
            <p className="text-md font-bold">{t('vehicleRegisterNumber')}:</p>
            <p className="ml-3">{vehicle.registerNumber}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleBrand')}:</p>
            <p className="ml-3">{vehicle.brand}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleModel')}:</p>
            <p className="ml-3">{vehicle.model}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleMileage')}:</p>
            <p className="ml-3">{vehicle.mileage}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleVIN')}:</p>
            <p className="ml-3">{vehicle.VIN}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleRunningHours')}:</p>
            <p className="ml-3">{vehicle.runningHours}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleWarranty')}:</p>
            <p className="ml-3">{vehicle.warranty ? formatDate(vehicle.warranty) : '-'}</p>
          </div>
          <div className="flex mt-1">
            <p className="text-md font-bold">{t('vehicleType')}:</p>
            <p className="ml-3">{t(`VehicleType:${vehicle.type}`)}</p>
          </div>
        </div>
        <div className="border border-t-1 my-8" />
        <div>
          <h2 className="text-2xl leading-6 font-bold">{t('serviceEvent')}</h2>
          {vehicle.serviceEvents.map((serviceEvent) => (
            <div className="mt-5" key={serviceEvent.id}>
              <div>
                <h2 className="text-md leading-6 font-bold">
                  {t('vehicleMaintenance')}: {serviceEvent.name}
                </h2>
                <div className="flex mt-2">
                  <p className="text-md font-bold">{t('vehicleMaintenanceDay')}:</p>
                  <p className="ml-3">{formatDate(serviceEvent.serviceDate)}</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-md font-bold">{t('vehicleMaintenanceType')}:</p>
                  <p className="ml-3">{t(`ServiceEventType:${serviceEvent.type}`)}</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-md font-bold">{t('vehicleMileage')}:</p>
                  <p className="ml-3">{serviceEvent.mileage}</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-md font-bold">{t('partner')}:</p>
                  <p className="ml-3">{serviceEvent.partner?.name || '-'}</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-md font-bold">{t('vehicleMaintenanceRunninghours')}:</p>
                  <p className="ml-3">{serviceEvent.runningHours}</p>
                </div>
                <div className="flex mt-1">
                  <p className="text-md font-bold">{t('vehicleDescription')}:</p>
                  <p className="ml-3">{serviceEvent.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
