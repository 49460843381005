import _ from 'lodash';
import { sharedTranslations } from 'shared-ui/lib/shared-translations';
import HomeCardReminderTranslation from './components/scenes/home-scene/HomeCardReminderTranslation';
import serviceEventCompleteReminderFormTranslation from './form/service-event/service-event-complete-reminder.form.translation';
import AcceptInvitationSceneTranslation from './scenes/auth/accept-invitation/AcceptInvitationSceneTranslation';
import ConfirmSceneTranslation from './scenes/auth/confirm/ConfirmSceneTranslation';
import ForgotSuccessSceneTranslation from './scenes/auth/forgot-success/ForgotSuccessSceneTranslation';
import ForgotSceneTranslation from './scenes/auth/forgot/ForgotSceneTranslation';
import LoginSceneTranslation from './scenes/auth/login/LoginSceneTranslation';
import RegisterSceneTranslation from './scenes/auth/register/register.translation';
import ResetSuccessSceneTranslation from './scenes/auth/reset-success/ResetSuccessSceneTranslation';
import ResetPasswordSceneTranslation from './scenes/auth/reset/ResetPasswordSceneTranslation';
import { FaultCreateSceneTranslation } from './scenes/fault/FaultCreateScene.translation';
import FaultIndexSceneTranslation from './scenes/fault/FaultIndexSceneTranslation';
import FaultShowSceneTranslation from './scenes/fault/FaultShowSceneTranslation';
import { FeedbackSceneTranslation } from './scenes/feedback/FeedbackScene.translation';
import HomeSceneTranslation from './scenes/HomeSceneTranslation';
import ReminderCreateSceneTranslation from './scenes/reminder/ReminderCreateSceneTranslation';
import ReminderEditSceneTranslation from './scenes/reminder/ReminderEditSceneTranslation';
import { ReminderIndexSceneTranslation } from './scenes/reminder/ReminderIndexSceneTranslation';
import ServiceEventCompleteReminderSceneTranslation from './scenes/service-event/ServiceEventCompleteReminderSceneTranslation';
import ServiceEventCreateSceneTranslation from './scenes/service-event/ServiceEventCreateSceneTranslation';
import ServiceEventEditSceneTranslation from './scenes/service-event/ServiceEventEditSceneTranslation';
import ServiceEventShowSceneTranslation from './scenes/service-event/ServiceEventShowSceneTranslation';
import BusinessEditSceneTranslation from './scenes/settings/BusinessEditSceneTranslation';
import UserEditSceneTranslation from './scenes/settings/UserEditSceneTranslation';
import { StripeCancelSceneTranslation } from './scenes/stripe/StripeCancel.translation';
import { StripeChoosePaymentSceneTranslation } from './scenes/stripe/StripeChoosePaymentScene.translation';
import { StripeSceneTranslation } from './scenes/stripe/StripeScene.translation';
import { StripeSuccessSceneTranslation } from './scenes/stripe/StripeSuccess.translation';
import { StripeUseVoucherSceneTranslation } from './scenes/stripe/StripeUseVoucherScene.translation';
import { TeamCreateSceneTranslation } from './scenes/team/TeamCreateScene.translation';
import { TeamIndexSceneTranslation } from './scenes/team/TeamIndexScene.translation';
import VehicleCreateSceneTranslation from './scenes/vehicle/VehicleCreateSceneTranslation';
import VehicleEditSceneTranslation from './scenes/vehicle/VehicleEditSceneTranslation';
import { VehicleIndexSceneTranslation } from './scenes/vehicle/VehicleIndexScene.translation';
import VehicleShowSceneTranslation from './scenes/vehicle/VehicleShowSceneTranslation';
import VehicleTransferSceneTranslation from './scenes/vehicle/VehicleTransferScene.translation';
import { commonTranslation } from './translations/common.translation';
import { errorMessageTranslation } from './translations/errorMessage.translation';
import { FeedbackTranslation } from './translations/feedback.translation';
import { licenseStatusTranslation } from './translations/license-status.translation';
import sidebarTranslation from './translations/sidebar.translation';
import { voucherTranslation } from './translations/voucher.translation';

export default _.merge(
  sharedTranslations,
  HomeSceneTranslation,
  HomeCardReminderTranslation,
  VehicleShowSceneTranslation,
  VehicleEditSceneTranslation,
  VehicleCreateSceneTranslation,
  UserEditSceneTranslation,
  ServiceEventCompleteReminderSceneTranslation,
  ServiceEventCreateSceneTranslation,
  ServiceEventEditSceneTranslation,
  ServiceEventShowSceneTranslation,
  ReminderEditSceneTranslation,
  ReminderCreateSceneTranslation,
  ResetSuccessSceneTranslation,
  ResetPasswordSceneTranslation,
  RegisterSceneTranslation,
  LoginSceneTranslation,
  ForgotSuccessSceneTranslation,
  ForgotSceneTranslation,
  ConfirmSceneTranslation,
  sidebarTranslation,
  serviceEventCompleteReminderFormTranslation,
  BusinessEditSceneTranslation,
  ReminderIndexSceneTranslation,
  VehicleIndexSceneTranslation,
  TeamIndexSceneTranslation,
  TeamCreateSceneTranslation,
  AcceptInvitationSceneTranslation,
  FaultIndexSceneTranslation,
  FaultShowSceneTranslation,
  StripeSceneTranslation,
  StripeCancelSceneTranslation,
  StripeSuccessSceneTranslation,
  VehicleTransferSceneTranslation,
  FaultCreateSceneTranslation,
  licenseStatusTranslation,
  StripeChoosePaymentSceneTranslation,
  StripeUseVoucherSceneTranslation,
  voucherTranslation,
  commonTranslation,
  FeedbackSceneTranslation,
  FeedbackTranslation,
  errorMessageTranslation
);
