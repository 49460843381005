import { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { ActivateYourAccount } from '../components/ActivateYourAccount';
import { DashboardLayout } from '../components/DashboardLayout';
import { routesConfig } from '../config/routes.config';
import { useSideBarConfig } from '../config/sidebar.config';
import UserContext from '../contexes/UserContext';
import { HomeScene } from './HomeScene';
import { FaultCreateScene } from './fault/FaultCreateScene';
import { FaultIndexScene } from './fault/FaultIndexScene';
import { FaultShowScene } from './fault/FaultShowScene';
import { FeedbackScene } from './feedback/FeedbackScene';
import { ReminderCreateScene } from './reminder/ReminderCreateScene';
import { ReminderEditScene } from './reminder/ReminderEditScene';
import { ReminderIndexScene } from './reminder/ReminderIndexScene';
import { ServiceEventCompleteReminderScene } from './service-event/ServiceEventCompleteReminderScene';
import { ServiceEventCreateScene } from './service-event/ServiceEventCreateScene';
import { ServiceEventEditScene } from './service-event/ServiceEventEditScene';
import { ServiceEventShowScene } from './service-event/ServiceEventShowScene';
import { BusinessEditScene } from './settings/BusinessEditScene';
import { UserEditScene } from './settings/UserEditScene';
import { StripeCancel } from './stripe/StripeCancel';
import { StripeChoosePaymentScene } from './stripe/StripeChoosePaymentScene';
import { StripeScene } from './stripe/StripeScene';
import { StripeSuccess } from './stripe/StripeSucces';
import { StripeUseVoucherScene } from './stripe/StripeUseVoutcherScene';
import { TeamCreateScene } from './team/TeamCreateScene';
import { TeamIndexScene } from './team/TeamIndexScene';
import { VehicleCreateScene } from './vehicle/VehicleCreateScene';
import { VehicleEditScene } from './vehicle/VehicleEditScene';
import { VehicleIndexScene } from './vehicle/VehicleIndexScene';
import { VehicleShowScene } from './vehicle/VehicleShowScene';
import { VehicleTransferScene } from './vehicle/VehicleTransferScene';

export const DashboardRouter = () => {
  const [user] = useContext(UserContext);
  const nav = useSideBarConfig();

  if (user && !user.isConfirmed) {
    return <ActivateYourAccount />;
  }

  return (
    <DashboardLayout user={user} nav={nav} homeUrl={routesConfig.DASHBOARD}>
      <Routes>
        <Route path={routesConfig.SETTINGS.TEAM.INDEX} element={<TeamIndexScene />} />
        <Route path={routesConfig.SETTINGS.TEAM.INVITE} element={<TeamCreateScene />} />

        <Route path={routesConfig.VEHICLES.INDEX} element={<VehicleIndexScene />} />
        <Route path={routesConfig.VEHICLES.CREATE} element={<VehicleCreateScene />} />
        <Route path={routesConfig.VEHICLES.TRANSFER} element={<VehicleTransferScene />} />
        <Route path={routesConfig.VEHICLES.SHOW} element={<VehicleShowScene />} />
        <Route path={routesConfig.VEHICLES.EDIT} element={<VehicleEditScene />} />

        <Route path={routesConfig.REMINDER.CREATE} element={<ReminderCreateScene />} />
        <Route path={routesConfig.REMINDER.EDIT} element={<ReminderEditScene />} />
        <Route path={routesConfig.REMINDER.INDEX} element={<ReminderIndexScene />} />

        <Route path={routesConfig.FAULT.INDEX} element={<FaultIndexScene />} />
        <Route path={routesConfig.FAULT.CREATE} element={<FaultCreateScene />} />
        <Route path={routesConfig.FAULT.SHOW} element={<FaultShowScene />} />
        <Route path={routesConfig.FAULT.CREATE_REMINDER} element={<ReminderCreateScene />} />

        <Route
          path={routesConfig.SERVICE_EVENTS.COMPLETE_REMINDER}
          element={<ServiceEventCompleteReminderScene />}
        />
        <Route path={routesConfig.SERVICE_EVENTS.CREATE} element={<ServiceEventCreateScene />} />
        <Route path={routesConfig.SERVICE_EVENTS.EDIT} element={<ServiceEventEditScene />} />
        <Route path={routesConfig.SERVICE_EVENTS.SHOW} element={<ServiceEventShowScene />} />

        <Route path={routesConfig.VEHICLES.VOUCHER} element={<StripeUseVoucherScene />} />
        <Route path={routesConfig.VEHICLES.CHOOSE_PAYMENT} element={<StripeChoosePaymentScene />} />
        <Route path={routesConfig.VEHICLES.BUY} element={<StripeScene />} />
        <Route path={routesConfig.VEHICLES.PAYMENT_SUCCESS} element={<StripeSuccess />} />
        <Route path={routesConfig.VEHICLES.PAYMENT_CANCEL} element={<StripeCancel />} />
        <Route path={routesConfig.SETTINGS.USER_EDIT} element={<UserEditScene />} />

        <Route path={routesConfig.DASHBOARD} element={<HomeScene />} />
        <Route path={routesConfig.FEEDBACK} element={<FeedbackScene />} />
        <Route path={routesConfig.SETTINGS.USER_EDIT} element={<UserEditScene />} />

        {user.isBusinessAccountOwner ? (
          <Route path={routesConfig.SETTINGS.BUSINESS_EDIT} element={<BusinessEditScene />} />
        ) : undefined}
      </Routes>
    </DashboardLayout>
  );
};
