export const StripeSceneTranslation = {
  fi: {
    StripeScene: {
      title: 'Tilauslomake',
      subTitle: 'Osta ajoneuvolle lisenssi',
      price: 'Hinta (sis. alv):',
      cost: '9,90 eur/vuosi',
      pay: 'Siirry maksamaan',
      acceptTerms: 'Olen hyväksynyt tilausehdot',
      back: 'Takaisin',
      include: 'Sisältyy',
      feature1: '- 12 kk käyttöoikeus sähköiseen huoltokirjaan',
      feature2: '- 1 ajoneuvo',
      feature3: '- Sisältää huoltomerkinnät ja muistutukset',
      instructions1:
        'Mahdollinen <span class="text-red-500">alennuskoodi</span> syötetään maksuikkunassa "anna tarjouskoodi" ja paina käytä.',
      instructions2: 'Maksun jälkeen palaat automaattisesti takaisin palveluumme.',
    },
  },
};
