import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared-ui/lib/components/Card';
import { VehicleName } from 'shared-ui/lib/components/VehicleName';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../../../config/routes.config';
import { HomeSceneQueryResult } from '../../../generated/graphql';
import { ArrowButton } from './ArrowButton';

type Props = {
  data: HomeSceneQueryResult['data'];
};

export const HomeCardReminder = ({ data }: Props) => {
  const { t } = useTranslation('HomeCardReminder');
  return (
    <Card>
      <h2 className="text-lg leading-6 font-bold text-primary">{t('title')}</h2>
      <>
        {data?.reminders.list.map((reminder) => {
          const isLate = moment().isSameOrAfter(moment(reminder.date));

          return (
            <div className="mt-3" key={reminder.id}>
              <div className="grid grid-cols-4 items-center">
                <div className="inline-flex col-span-3 items-center">
                  <div className="grid">
                    <p
                      className={`text-md truncate ${
                        isLate ? 'text-red-color font-bold' : 'text-gray-900'
                      }`}
                    >
                      {reminder.title}
                    </p>
                    <p className="text-sm  text-gray-color">
                      <VehicleName vehicle={reminder.vehicle} />
                    </p>
                    <p className={`text-sm  ${isLate ? 'text-red-color' : 'text-gray-color'} `}>
                      {formatDate(reminder.date)}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end">
                  <ArrowButton to={routesConfig.VEHICLES.SHOW.replace(':id', reminder.vehicleId)} />
                </div>
              </div>
            </div>
          );
        })}
      </>
    </Card>
  );
};
