import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import * as Yup from 'yup';

const voucher: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'voucher',
    label: 'Voucher:voucher',
    type: 'text',
  },
};

const isAccepted: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.boolean().required(),
  props: {
    name: 'isAccepted',
    label: 'User:isAccepted',
  },
};

export const voucherInput = {
  voucher,
  isAccepted
};
