import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GenericIndexScene } from 'shared-ui/lib/components/table/TableX';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { faultColumns } from '../../columns/fault.columns';
import { routesConfig } from '../../config/routes.config';
import { useFaultIndexSceneLazyQuery } from '../../generated/graphql';

export const FaultIndexScene = (): JSX.Element => {
  const { t } = useTranslation('FaultIndexScene');
  const query = useFaultIndexSceneLazyQuery(setupQueryConfig({ where: { isDone: false } }));

  return (
    <GenericIndexScene
      tNs="FaultIndexScene"
      dataPath="faults.list"
      countPath="faults.totalCount"
      Tool={
        <Link
          to={routesConfig.FAULT.CREATE}
          className="float-right py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
        >
          {t('add')}
        </Link>
      }
      query={query}
      where={{ isDone: false }}
      columns={[
        faultColumns.userName,
        faultColumns.createdAt,
        faultColumns.vehicleRegisterNumber,
        faultColumns.linkToFault,
      ]}
    />
  );
};
