import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from 'shared-ui/lib/components/Button';
import { Card } from 'shared-ui/lib/components/Card';
import { CardTitle } from 'shared-ui/lib/components/CardTitle';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { routesConfig } from '../../config/routes.config';
import ReFetchContext from '../../contexes/ReFetchContext';
import UserContext from '../../contexes/UserContext';
import { userForm } from '../../form/user/user.form';
import {
  useAccountDeleteRequestMutation,
  useChangeUserInformationMutation,
  useUserEditWhoAmIQuery,
} from '../../generated/graphql';

export const UserEditScene = (): JSX.Element => {
  const { t } = useTranslation('UserEditScene');
  const refetch = useContext(ReFetchContext);
  const [user] = useContext(UserContext);

  const [updateUser, { loading }] = useChangeUserInformationMutation({
    onCompleted: () => {
      toast.success(t('General:updated'));
      refetch();
    },
  });

  const [requestAccountDelete] = useAccountDeleteRequestMutation({
    onCompleted: () => {
      toast.success(t('deleteUserSuccess'));
    },
  });

  const query = useUserEditWhoAmIQuery(setupQueryConfig());

  const handleSubmit = useHandleSubmit(updateUser);

  const onDelete = () => {
    if (confirm(t('deleteUserConfirm'))) {
      requestAccountDelete();
    }
  };

  return (
    <>
      <GenericFormScene
        type="edit"
        title={t('editInformation')}
        query={query}
        queryDataPath="whoAmI"
        onSubmit={handleSubmit}
        form={userForm}
        isSubmitLoading={loading}
        backLink={routesConfig.DASHBOARD}
      />

      {user.type === 'consumer' && (
        <div className="mt-6 mx-auto w-full max-w-3xl sm:px-2">
          <Card>
            <CardTitle>{t('deleteUserTitle')}</CardTitle>
            <p className="mb-6">{t('deleteUserText')}</p>
            <Button type="button" onClick={onDelete}>
              {t('deleteUserButton')}
            </Button>
          </Card>
        </div>
      )}
    </>
  );
};
