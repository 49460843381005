import { useTranslation } from 'react-i18next';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { routesConfig } from '../../config/routes.config';
import { inviteUserForm } from '../../form/invite-user/invite-user.form';
import { useTeamCreateSceneMutation } from '../../generated/graphql';

export const TeamCreateScene = (): JSX.Element => {
  const { t } = useTranslation('TeamCreateScene');
  const onComplete = useOnCompleteRedirect(routesConfig.SETTINGS.TEAM.INDEX);

  const [inviteUser, { loading }] = useTeamCreateSceneMutation(onComplete);

  const handleSubmit = useHandleSubmit(inviteUser);

  return (
    <GenericFormScene
      type="create"
      title={t('inviteUser')}
      onSubmit={handleSubmit}
      form={inviteUserForm}
      isSubmitLoading={loading}
      backLink={routesConfig.SETTINGS.TEAM.INDEX}
    />
  );
};
