import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthErrorView } from 'shared-ui/lib/components/auth/AuthErrorView';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import logout from 'shared-ui/lib/utils/logout';
import { routesConfig } from '../config/routes.config';
import ReFetchContext from '../contexes/ReFetchContext';
import UserContext from '../contexes/UserContext';
import { useWhoAmILazyQuery, WhoAmIQuery } from '../generated/graphql';

type Props = {
  children: JSX.Element;
};

export const RequireAuth = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [isReFetchRequested, setIsReFetchRequested] = useState(false);
  const [user, setUser] = useState<WhoAmIQuery['whoAmI'] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onError = useCallback(() => {
    logout();
    navigate(routesConfig.AUTH.LOGIN);
  }, [navigate]);

  const [whoAmI, { data }] = useWhoAmILazyQuery({
    ...setupQueryConfig(),
    errorPolicy: 'none',
    onError,
  });

  useEffect(() => {
    (async () => {
      await whoAmI();
      setIsLoading(false);
    })();
  }, [whoAmI, setIsLoading]);

  useEffect(() => {
    if (data?.whoAmI) {
      setUser(data.whoAmI);
    }
  }, [data]);

  useEffect(() => {
    if (isReFetchRequested) {
      setIsReFetchRequested(false);
      whoAmI();
    }
  }, [whoAmI, isReFetchRequested, setIsReFetchRequested]);

  const refresh = useCallback(() => {
    setIsReFetchRequested(true);
  }, [setIsReFetchRequested]);

  if (isLoading) return <AuthLoadingView />;
  if (!user)
    return <AuthErrorView loginRoute={routesConfig.AUTH.LOGIN} errorReason="Unauthorized" />;

  return (
    <ReFetchContext.Provider value={refresh}>
      <UserContext.Provider value={[user, setUser]}>{props.children}</UserContext.Provider>
    </ReFetchContext.Provider>
  );
};
