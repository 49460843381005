import { Link } from 'react-router-dom';
import { routesConfig } from '../../../../config/routes.config';

type Props = {
  vehicleId: string;
  id: string;
};

export const ReminderListItemIconCheck = ({ vehicleId, id }: Props) => {
  return (
    <Link
      to={routesConfig.SERVICE_EVENTS.COMPLETE_REMINDER.replace(':vehicleId', vehicleId).replace(
        ':id',
        id
      )}
    >
      <div className=" border border-primary p-2 rounded-md disabled:bg-gray-300 hover:bg-secondary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
      </div>
    </Link>
  );
};
