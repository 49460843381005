import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { userInputs } from '../../inputs/user.inputs';

const form: FormInput[] = [userInputs.name, userInputs.email, userInputs.isEmployee];

export const inviteUserForm = createForm(form);

export type InviteUserFormBody = {
  email: string;
  name: string;
  isEmployee: boolean;
};
