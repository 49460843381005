import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GenericFormScene } from 'shared-ui/lib/components/generic-form-scene/GenericFormScene';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { parseGraphqlErrors } from 'shared-ui/lib/utils/get-gql-error';
import { routesConfig } from '../../config/routes.config';
import ReFetchContext from '../../contexes/ReFetchContext';
import UserContext from '../../contexes/UserContext';
import { businessForm, BusinessFormBody } from '../../form/business/business.form';
import { useBusinessQuery, useUpdateBusinessMutation } from '../../generated/graphql';
import { useUploadFile } from '../../hooks/use-upload-file';

export const BusinessEditScene = (): JSX.Element => {
  const { t } = useTranslation('BusinessEditScene');
  const { uploadFile, isImageLoading } = useUploadFile({ type: 'business-image' });
  const [user] = useContext(UserContext);
  const refresh = useContext(ReFetchContext);
  const [updateBusiness, { loading }] = useUpdateBusinessMutation({
    onCompleted: () => {
      toast.success(t('General:updated'));
    },
  });

  const businessQuery = useBusinessQuery(setupQueryConfig());

  const handleSubmit = async ({ photo, ...body }: BusinessFormBody) => {
    const { data, errors } = await updateBusiness({ variables: { body } });
    if (!data) throw new Error(parseGraphqlErrors(errors));
    if (!user.business) throw new Error('[BusinessEditScene] not business user');
    await uploadFile(photo, user.business.id);
    refresh();
  };

  return (
    <GenericFormScene
      type="edit"
      title={t('editInformation')}
      query={businessQuery}
      queryDataPath="business"
      onSubmit={handleSubmit}
      form={businessForm}
      isSubmitLoading={loading || isImageLoading}
      backLink={routesConfig.DASHBOARD}
    />
  );
};
