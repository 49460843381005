import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'shared-ui/lib/components/Card';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { HomeCardReminder } from '../components/scenes/home-scene/HomeCardReminder';
import { HomeCardVehicle } from '../components/scenes/home-scene/HomeCardVehicle';
import { routesConfig } from '../config/routes.config';
import { useHomeSceneQuery } from '../generated/graphql';

export const HomeScene = () => {
  const { data } = useHomeSceneQuery(setupQueryConfig());
  const { t } = useTranslation('HomeScene');

  const emptyVehicles = (
    <Card>
      <div className="text-sm font-medium text-gray-color mt-2 text-center">
        <p>{t('emptyVehiclesTitle')}</p>
        <Link
          to={routesConfig.VEHICLES.CREATE}
          className="text-primary cursor-pointer font-bold underline"
        >
          <p className="mt-2">{t('emptyVehiclessAddNew')}</p>
        </Link>
      </div>
    </Card>
  );

  const emptyReminders = (
    <Card>
      <div>
        <p className="text-lg leading-6 font-bold text-primary">{t('emptyRemindersTitle')}</p>
        <p className="text-sm font-medium text-gray-color mt-2">{t('emptyRemindersInformation')}</p>
      </div>
    </Card>
  );

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3 mt-1 sm:mt-0">
        {data?.reminders.list.length === 0 ? emptyReminders : <HomeCardReminder data={data} />}
        {data?.vehicles.list.length === 0 ? emptyVehicles : <HomeCardVehicle data={data} />}
      </div>
    </>
  );
};
