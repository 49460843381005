import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { routesConfig } from '../../../config/routes.config';

export const RegisterScene = (): JSX.Element => {
  const { t } = useTranslation('RegisterBusinessScene');

  return (
    <AuthLayout>
      <AuthLogoTitle
        title={t('register')}
        subTitle={t('RegisterCommon:iHaveAccount')}
        to={routesConfig.AUTH.LOGIN}
        linkText={t('RegisterCommon:login')}
      />
      <AuthCard>
        <p className="text-md text-gray-900 text-center"> {t('RegisterBusinessScene:idType')}</p>
        <div className="mt-6 mb-3">
          <Link
            to={routesConfig.AUTH.REGISTER.CONSUMER}
            className="w-full flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          >
            {t('RegisterBusinessScene:client')}
          </Link>
        </div>
        <div>
          <Link
            to={routesConfig.AUTH.REGISTER.BUSINESS}
            className="w-full flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
          >
            {t('RegisterBusinessScene:businessClient')}
          </Link>
        </div>
      </AuthCard>
    </AuthLayout>
  );
};
