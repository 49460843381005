import { VehicleName } from 'shared-ui/lib/components/VehicleName';
import { HomeSceneQuery } from '../../../generated/graphql';
import { LicenseBadge } from '../../LicenseBadge';

type Props = {
  vehicle: HomeSceneQuery['vehicles']['list'][0];
};

export const HomeCardVehicleName = ({ vehicle }: Props) => {
  const features = [];
  if (vehicle.mileage && vehicle.mileage > 0) features.push(`${vehicle.mileage} km`);
  if (vehicle.runningHours && vehicle.runningHours > 0) features.push(`${vehicle.runningHours} h`);

  let description = '-';
  if (features.length) description = features.join(', ');

  return (
    <>
      <LicenseBadge licenseStatus={vehicle.licenseStatus} />
      <h2 className="text-md leading-6 text-primary truncate">
        <VehicleName vehicle={vehicle} />
      </h2>
      <div style={{ minHeight: '44px' }}>
        <p className="text-gray-color text-sm mt-1">{vehicle.registerNumber}</p>
        <p className="text-gray-color text-sm mt-1">{description}</p>
      </div>
    </>
  );
};
