import { Link } from 'react-router-dom';
import { VehicleName } from 'shared-ui/lib/components/VehicleName';
import { TableColumn } from 'shared-ui/lib/releox-engine/react/generic-table/TableColumn';
import { formatDate } from 'shared-ui/lib/utils/format-date';
import { routesConfig } from '../config/routes.config';
import { FaultIndexSceneQuery } from '../generated/graphql';

const userName: TableColumn = {
  field: 'user.name',
  text: 'Fault:userName',
  sort: true,
};

const createdAt: TableColumn = {
  field: 'createdAt',
  text: 'Fault:createdAt',
  sort: true,
  formatter: (d) => {
    const date = formatDate(d.createdAt);

    // new badge
    if (d.isRead === false) {
      return (
        <div className="flex items-center">
          <span className="mr-2">{date}</span>
          <span className="bg-primary text-white text-xs py-1 px-3 rounded-md">Uusi</span>
        </div>
      );
    }

    return `${date}`;
  },
};

const vehicleRegisterNumber: TableColumn = {
  field: 'vehicle.name',
  text: 'Fault:vehicleName',
  sort: true,
  formatter: (data: FaultIndexSceneQuery['faults']['list'][0]) => {
    return <VehicleName vehicle={data.vehicle} />;
  },
};

const linkToFault: TableColumn = {
  field: 'id',
  text: 'General:link',
  formatter: (data: FaultIndexSceneQuery['faults']['list'][0]) => (
    <Link
      to={routesConfig.FAULT.SHOW.replace(':id', data.id)}
      className="hover:text-primary text-gray-color"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 5l7 7m0 0l-7 7m7-7H3"
        />
      </svg>
    </Link>
  ),
};

export const faultColumns = {
  userName,
  vehicleRegisterNumber,
  createdAt,
  linkToFault,
};
