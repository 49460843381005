import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AuthButton } from 'shared-ui/lib/components/auth/AuthButton';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthErrorView } from 'shared-ui/lib/components/auth/AuthErrorView';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import { useOnCompleteRedirect } from 'shared-ui/lib/hooks/apollo/use-on-complete-redirect';
import { ParseUrl } from 'shared-ui/lib/services/parse-url';
import { routesConfig } from '../../../config/routes.config';
import { acceptInvitationForm } from '../../../form/accept-invitation/accept-invitation.form';
import {
  useAcceptInvitationMutation,
  useVerifyAcceptInvitationTokenMutation,
} from '../../../generated/graphql';

export const AcceptInvitationScene = () => {
  const { t } = useTranslation('AcceptInvitationScene');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [validInviteToken, setValidInviteToken] = useState('');
  const location = useLocation();

  const [validateToken, { error }] = useVerifyAcceptInvitationTokenMutation();

  const onComplete = useOnCompleteRedirect(routesConfig.AUTH.LOGIN);

  const [acceptInvitation, { loading }] = useAcceptInvitationMutation(onComplete);

  useEffect(() => {
    (async () => {
      try {
        const inviteToken = ParseUrl.parseLocation(location, 'inviteToken');
        const { data } = await validateToken({
          variables: { body: { inviteToken } },
        });
        if (!data) throw new Error('Cannot validate token');
        setValidInviteToken(inviteToken);
        setIsLoading(false);
      } catch (e) {
        setShowError(true);
      }
    })();
  }, [location, setShowError, validateToken]);

  useEffect(() => {
    if (error) setShowError(true);
  }, [error]);

  const handleSubmit = useHandleSubmit(acceptInvitation, { body: { token: validInviteToken } });

  if (showError)
    return <AuthErrorView loginRoute={routesConfig.AUTH.LOGIN} errorReason="Invalid token" />;
  if (isLoading) return <AuthLoadingView />;

  return (
    <AuthLayout>
      <AuthLogoTitle title={t('title')} />
      <AuthCard>
        <FormGen
          wrapperClassNames="space-y-4"
          form={acceptInvitationForm}
          onSubmit={handleSubmit}
          FooterElement={<AuthButton loading={loading} type="submit" label={t('submit')} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
