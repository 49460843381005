import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthCard } from 'shared-ui/lib/components/auth/AuthCard';
import { AuthLayout } from 'shared-ui/lib/components/auth/AuthLayout';
import { AuthLogoTitle } from 'shared-ui/lib/components/auth/AuthLogoTitle';
import { FormGen } from 'shared-ui/lib/components/generic-form-scene/FormGen';
import { useHandleSubmit } from 'shared-ui/lib/hooks/apollo/use-handle-submit';
import login from 'shared-ui/lib/utils/login';
import { RegisterConsumerFooter } from '../../../components/RegisterConsumerFooter';
import { routesConfig } from '../../../config/routes.config';
import { consumerRegisterForm } from '../../../form/register/consumer-register.form';
import { useRegisterConsumerMutation } from '../../../generated/graphql';

export const RegisterConsumerScene = (): JSX.Element => {
  const { t } = useTranslation('RegisterConsumerScene');
  const navigate = useNavigate();
  const [register, { loading }] = useRegisterConsumerMutation({
    onCompleted: (data) => {
      if (data) {
        login(data.registerConsumerUser.token);
        navigate(routesConfig.DASHBOARD);
      }
    },
  });

  const handleSubmit = useHandleSubmit(register);

  return (
    <AuthLayout>
      <AuthLogoTitle
        title={t('register')}
        subTitle={t('RegisterCommon:iHaveAccount')}
        to={routesConfig.AUTH.LOGIN}
        linkText={t('RegisterCommon:login')}
      />
      <AuthCard>
        <FormGen
          form={consumerRegisterForm}
          onSubmit={handleSubmit}
          wrapperClassNames="space-y-4"
          FooterElement={<RegisterConsumerFooter loading={loading} />}
        />
      </AuthCard>
    </AuthLayout>
  );
};
