export default {
  fi: {
    FaultShowScene: {
      backLink: 'Takaisin',
      delete: 'Poista',
      createReminder: 'Luo muistutus',
      description: 'Kuvaus',
      createdAt: 'Luotu',
      userName: 'Ilmoittaja',
      markAsRead: 'Merkitse luetuksi',
    },
  },
};
