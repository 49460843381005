import { createForm } from 'shared-ui/lib/releox-engine/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/releox-engine/form/FormInput';
import { businessInput } from '../../inputs/business.input';

const form: FormInput[] = [businessInput.name, businessInput.photo];

export const businessForm = createForm(form);

export type BusinessFormBody = {
  name: string;
  photo: string;
};
