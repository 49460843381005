import {
  AnnotationIcon,
  BellIcon,
  BriefcaseIcon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  LogoutIcon,
  PlusIcon,
  TruckIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { List } from 'immutable';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexes/UserContext';
import { routesConfig } from './routes.config';

const sidebarConfig: ListItem[] = [
  {
    order: 1,
    name: 'createVehicle',
    href: routesConfig.VEHICLES.CREATE,
    icon: PlusIcon,
    current: false,
    preventActive: true,
  },
  {
    order: 2,
    name: 'home',
    href: routesConfig.DASHBOARD,
    icon: HomeIcon,
    current: false,
    exact: true,
  },
  {
    order: 4,
    name: 'reminders',
    href: routesConfig.REMINDER.INDEX,
    icon: CalendarIcon,
    current: false,
  },
  {
    order: 5,
    name: 'vehicles',
    href: routesConfig.VEHICLES.INDEX,
    icon: TruckIcon,
    current: false,
  },
  {
    order: 8,
    name: 'settings',
    href: routesConfig.SETTINGS.USER_EDIT,
    icon: CogIcon,
    current: false,
  },
];

type ListItem = {
  order: number;
  name: string;
  href: string;
  icon: typeof HomeIcon;
  current: boolean;
  preventActive?: boolean;
  exact?: boolean;
};

export const useSideBarConfig = () => {
  const [user] = useContext(UserContext);
  const [list, setList] = useState<ListItem[]>([]);

  useEffect(() => {
    let localList = List<ListItem>(sidebarConfig);

    if (user.business) {
      localList = localList.push({
        order: 3,
        name: 'faults',
        href: routesConfig.FAULT.INDEX,
        icon: BellIcon,
        current: false,
      });
      localList = localList.push({
        order: 9,
        name: 'feedback',
        href: routesConfig.FEEDBACK,
        icon: AnnotationIcon,
        current: false,
      });
    }

    if (user.isBusinessAccountOwner) {
      localList = localList.push({
        order: 7,
        name: 'businessSettings',
        href: routesConfig.SETTINGS.BUSINESS_EDIT,
        icon: BriefcaseIcon,
        current: false,
      });

      localList = localList.push({
        order: 6,
        name: 'team',
        href: routesConfig.SETTINGS.TEAM.INDEX,
        icon: UsersIcon,
        current: false,
      });
    }

    localList = localList.push({
      order: 10,
      name: 'logOut',
      href: routesConfig.AUTH.LOGOUT,
      icon: LogoutIcon,
      current: false,
    });

    setList(localList.toArray().sort((a, b) => a.order - b.order));
  }, [user, setList]);

  return list;
};
