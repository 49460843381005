import { GenericIndexScene } from 'shared-ui/lib/components/table/TableX';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { reminderColumns } from '../../columns/reminder.columns';
import { useRemindersLazyQuery } from '../../generated/graphql';

export const ReminderIndexScene = () => {
  const query = useRemindersLazyQuery(setupQueryConfig());

  return (
    <GenericIndexScene
      tNs="ReminderIndexScene"
      dataPath="reminders.list"
      countPath="reminders.totalCount"
      query={query}
      columns={[
        reminderColumns.title,
        reminderColumns.date,
        reminderColumns.vehicle,
        reminderColumns.registerNumber,
        reminderColumns.linkToVehicle,
      ]}
    />
  );
};
