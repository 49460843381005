export const routesConfig = {
  AUTH: {
    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER: {
      CHOOSE: '/register',
      CONSUMER: '/register/consumer',
      BUSINESS: '/register/business',
    },
    FORGOT: '/forgot',
    FORGOT_SUCCESS: '/forgot-success',
    CONFIRM: '/confirm',
    ACCEPT_INVITATION: '/accept-invitation',
    RESET: '/reset',
    RESET_SUCCESS: '/reset-success',
  },
  SETTINGS: {
    ROOT: '/settings',
    USER_EDIT: '/settings/user',
    BUSINESS_EDIT: '/settings/business',
    TEAM: {
      INDEX: '/settings/team/index',
      INVITE: '/settings/team/invite',
    },
  },
  VEHICLES: {
    INDEX: '/vehicles',
    CREATE: '/vehicles/new',
    TRANSFER: '/vehicles/transfer',
    SHOW: '/vehicles/:id',
    CHOOSE_PAYMENT: '/vehicles/:id/choose-payment',
    VOUCHER: '/vehicles/:id/choose-payment/voucher',
    BUY: '/vehicles/:id/buy',
    PAYMENT_SUCCESS: '/vehicles/:id/success-payment',
    PAYMENT_CANCEL: '/vehicles/:id/cancel-payment',
    EDIT: '/vehicles/:id/edit',
    TEXT_ONLY: '/vehicles/:id/text-only',
  },
  SERVICE_EVENTS: {
    COMPLETE_REMINDER: '/vehicles/:vehicleId/reminders/:id/complete-reminder',
    CREATE: '/vehicles/:vehicleId/service-events/new',
    EDIT: '/vehicles/:vehicleId/service-events/:id/edit',
    SHOW: '/vehicles/:vehicleId/service-events/:id',
  },
  REMINDER: {
    INDEX: '/reminders',
    CREATE: '/vehicles/:vehicleId/reminder/new',
    EDIT: '/vehicles/:vehicleId/reminder/:id/edit',
  },
  FAULT: {
    CREATE: '/faults/new',
    INDEX: '/faults',
    SHOW: '/faults/:id',
    CREATE_REMINDER: '/faults/:faultId/vehicles/:vehicleId/reminder/new',
  },
  DASHBOARD: '/',
  FEEDBACK: '/feedback',
  REPORT_REMINDER: '/vehicles/:vehicleId/reminder/:id/report',
};
