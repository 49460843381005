export const licenseStatusTranslation = {
  fi: {
    LicenseStatus: {
      PENDING: 'Odottaa maksua',
      EXPIRED: 'Erääntynyt',
      EXPIRE_SOON: 'Erääntyy pian',
      PAID: 'Maksettu',
    },
  },
};
