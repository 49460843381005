export default {
  fi: {
    SideBar: {
      createVehicle: 'Lisää ajoneuvo',
      home: 'Etusivu',
      vehicles: 'Ajoneuvoni',
      reminders: 'Muistutukset',
      settings: 'Asetukset',
      logOut: 'Kirjaudu ulos',
      businessSettings: 'Yrityksen asetukset',
      team: 'Tiimi',
      faults: 'Vikailmoitukset',
      order: 'Tilaa',
      feedback: 'Anna palautetta',
    },
  },
};
