import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthLoadingView } from 'shared-ui/lib/components/auth/AuthLoadingView';
import { Details } from 'shared-ui/lib/components/Details';
import { Image } from 'shared-ui/lib/components/Image';
import { setupQueryConfig } from 'shared-ui/lib/config/query.config';
import { useParams } from 'shared-ui/lib/utils/use-params';
import { vehicleColumns } from '../../columns/vehicle.columns';
import { AddIconButton } from '../../components/scenes/vehicle-show-scene/AddIconButton';
import { EditIconButton } from '../../components/scenes/vehicle-show-scene/EditIconButton';
import { MaintenanceListItem } from '../../components/scenes/vehicle-show-scene/maintenance-list-item/MaintenanceListItem';
import { PaymentIconButton } from '../../components/scenes/vehicle-show-scene/PaymentIconButton';
import { ReminderListItem } from '../../components/scenes/vehicle-show-scene/reminder-list-item/ReminderListItem';
import { TransferIconButton } from '../../components/scenes/vehicle-show-scene/TransferIconButton';
import { routesConfig } from '../../config/routes.config';
import { useCreateTransferTokenMutation, useVehicleShowQuery } from '../../generated/graphql';

export const VehicleShowScene = () => {
  const { t } = useTranslation('VehicleShowScene');
  const { id } = useParams<{ id: string }>();
  const [showDetails, setShowDetails] = useState(false);

  const remindersRef = useRef<HTMLHeadingElement>(null);
  const serviceEventsRef = useRef<HTMLHeadingElement>(null);

  const [createTransferToken, { loading: transferTokenLoading }] = useCreateTransferTokenMutation({
    variables: { vehicleId: id },
    onCompleted: (d) => {
      if (d.createTransferToken) {
        toast.success(t('transferLinkSent'));
      }
    },
  });

  const scrollToReminders = useCallback(() => {
    if (remindersRef.current) {
      remindersRef.current.scrollIntoView();
    }
  }, [remindersRef]);

  const scrollToServiceEvents = useCallback(() => {
    if (serviceEventsRef.current) {
      serviceEventsRef.current.scrollIntoView();
    }
  }, [serviceEventsRef]);

  const columns = [
    vehicleColumns.name,
    vehicleColumns.registerNumber,
    vehicleColumns.brand,
    vehicleColumns.model,
    vehicleColumns.token,
    vehicleColumns.license,
    vehicleColumns.mileage,
    vehicleColumns.VIN,
    vehicleColumns.runningHours,
    vehicleColumns.modelYear,
    vehicleColumns.commissioning,
    vehicleColumns.warranty,
    vehicleColumns.type,
    vehicleColumns.description,
  ];

  const { data, loading } = useVehicleShowQuery(setupQueryConfig({ id }));

  if (loading || !data) return <AuthLoadingView />;

  const { vehicle } = data;

  const emptyRemainder = (
    <div className="text-sm font-medium text-gray-color mt-2">
      <p>{t('emptyRemainder')}</p>
      <Link
        to={routesConfig.REMINDER.CREATE.replace(':vehicleId', id)}
        className="text-primary cursor-pointer font-bold underline"
      >
        <p className="mt-2">{t('emptyRemainderAddNew')}</p>
      </Link>
    </div>
  );

  const emptyServiceEvent = (
    <div className="text-sm font-medium text-gray-color mt-2">
      <p>{t('emptyServiceEvent')}</p>
      <Link
        to={routesConfig.SERVICE_EVENTS.CREATE.replace(':vehicleId', id)}
        className="text-primary cursor-pointer font-bold underline"
      >
        <p className="mt-2">{t('emptyServiceEventAddNew')}</p>
      </Link>
    </div>
  );

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-3 mt-1 sm:mt-0">
      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-4 items-center">
            <h2 className="text-lg col-span-2 leading-6 font-bold text-primary">
              {t('vehicleInformation')}
            </h2>
            <div className="xl:flex xl:justify-end col-span-2 space-x-2">
              <div>
                <div className="float-right">
                  <TransferIconButton
                    onClick={createTransferToken}
                    loading={transferTokenLoading}
                  />
                </div>
                <div className="float-right">
                  <PaymentIconButton
                    to={routesConfig.VEHICLES.CHOOSE_PAYMENT.replace(':id', vehicle.id)}
                  />
                </div>
                <div className="float-right">
                  <EditIconButton to={routesConfig.VEHICLES.EDIT.replace(':id', vehicle.id)} />
                </div>
                <div className="clear-both" />
              </div>
              <div className="mt-2">
                <button onClick={scrollToReminders} className="float-right">
                  <div className="border border-primary p-2 rounded-md disabled:bg-gray-300 hover:bg-secondary hover:border-transparent xl:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-primary"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                </button>
                <button onClick={scrollToServiceEvents} className="float-right mr-2">
                  <div className="border border-primary  p-2 rounded-md disabled:bg-gray-300 hover:bg-secondary hover:border-transparent xl:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-primary"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                      />
                    </svg>
                  </div>
                </button>
                <div className="clear-both" />
              </div>
            </div>
          </div>
          <div className="max-w-screen-lg mt-6 mx-auto">
            <Image src={vehicle.photo} w={500} className="mx-auto rounded-md" />
          </div>
          <Link
            to={routesConfig.VEHICLES.TEXT_ONLY.replace(':id', id)}
            className="text-primary hover:text-secondary cursor-pointer font-bold underline text-sm"
          >
            <p className="mt-2">{t('printMaintenanceBoook')}</p>
          </Link>
          <div className="mt-6">
            <Details columns={columns} data={vehicle} showMore={showDetails ? undefined : 4} />
            <button
              className="text-secondary cursor-pointer hover:text-primary"
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? t('hideDetails') : t('showDetails')}
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-3 items-center">
            <h2 className="text-lg col-span-2 leading-6 font-bold text-primary" ref={remindersRef}>
              {t('reminder')}
            </h2>

            {data.vehicle.hasActiveLicense ? (
              <div className="flex justify-end">
                <AddIconButton to={routesConfig.REMINDER.CREATE.replace(':vehicleId', id)} />
              </div>
            ) : null}
          </div>

          {data.vehicle.hasActiveLicense ? (
            <>
              {data.vehicle.reminders.length === 0
                ? emptyRemainder
                : data.vehicle.reminders.map((reminder) => (
                    <ReminderListItem
                      key={reminder.id}
                      reminder={reminder}
                      vehicleId={vehicle.id}
                    />
                  ))}
            </>
          ) : (
            <p>{t('pleaseReactivateLicense')}</p>
          )}
        </div>
      </div>

      <div>
        <div className="bg-white py-4 px-3 shadow rounded-lg">
          <div className="grid grid-cols-3 items-center">
            <h2
              className="text-lg col-span-2 leading-6 font-bold text-primary"
              ref={serviceEventsRef}
            >
              {t('serviceEvent')}
            </h2>
            {data.vehicle.hasActiveLicense ? (
              <div className="flex justify-end">
                <AddIconButton
                  to={routesConfig.SERVICE_EVENTS.CREATE.replace(':vehicleId', vehicle.id)}
                />
              </div>
            ) : null}
          </div>

          {data.vehicle.hasActiveLicense ? (
            <>
              {data.vehicle.serviceEvents.length === 0
                ? emptyServiceEvent
                : data.vehicle.serviceEvents.map((serviceEvent) => (
                    <MaintenanceListItem
                      key={serviceEvent.id}
                      vehicleId={vehicle.id}
                      serviceEvent={serviceEvent}
                    />
                  ))}
            </>
          ) : (
            <p>{t('pleaseReactivateLicense')}</p>
          )}
        </div>
      </div>
    </div>
  );
};
